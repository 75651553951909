import CurrentQR from "Components/CurrentQR";
import IconButton from "Components/IconButton";
import StudentRenderer from "Components/ModelRenderers/StudentRenderer";
import MyBrand from "Components/MyBrand";
import MyLoadingSpinner from "Components/MyLoadingSpinner";
import { STAR_HOLLOW } from "Constants/Common";
import IStyle from "Interfaces/IStyle";
import { MyToast, MyToastContainer } from "Layouts/Toast";
import Student, { SchaleStudent } from "Models/Student";
import StudentMap from "Models/StudentMap";
import { copyToClipboard } from "Utils/Common";
import domToImage from 'dom-to-image';
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Accordion, Alert, Button, Col, Form, Nav, NavDropdown, Row, Toast } from "react-bootstrap";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import { useSearchParams } from "react-router-dom";

const BASE66_DIGITS = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_.~";

const styles:IStyle = {
	cardContainer: {
		display: "flex", 
		flexDirection: "row", 
		flexWrap: "wrap"
	},
	container: {
		padding: 10,
		margin: 5,
		border: "1px solid",
		// borderColor: "violet",
		borderRadius: 20,
		borderColor: "pink",
	},
	cardGroup: {
		margin: "1em",
		zIndex: 1,
		padding: "0 0.3em 0 0.3em",
		border: "1px solid",
		borderColor: "pink",
	},
	compactCardGroup: {
		margin: "1em 1em 0.1em 1em",
		zIndex: 1,
		padding: "0 0.3em 0 0.3em",
	},
	card: {
		textAlign: "center",
		display: "block",
		// margin: "auto",
		marginBottom: "auto",
		marginLeft: "auto",
		marginRight: "auto",
		// verticalAlign: "top"
	},
	spinnerRow: {
		// alignContent: "center",
		// alignItems: "center",
		// alignSelf: "center",
		// textAlign: "center"
		zIndex: 10
	},
	button: {
		marginRight: 5
	},
	buttonLink: {
		margin: 5,
	},
	selectButton: {
		marginRight: "1em",
		padding: "0.3em"
	}
}

function toBaseN(num: bigint|number, b: number):string {
	if (num == null || typeof(num) === "undefined")
		return null;
	if (typeof(num) === "number")
		num = BigInt(num);
	if (b <= 0 || b > 66)
		throw new Error("Unsupported base");
	const base = BigInt(b);
  
	if (num === 0n) {
	  return "0";
	}
  
	const result = [];
	while (num > 0n) {
	  const remainder = num % base;
	  result.unshift(BASE66_DIGITS[Number(remainder)]);
	  num = num / base;
	}
  
	return result.join("");
}

function fromBaseN(base66String: string, b: number): bigint {
	if (base66String == null || typeof(base66String) === "undefined")
		return null;
	if (b <= 0 || b > 66)
		throw new Error("Unsupported base");
	const base = BigInt(b);
	let result = 0n;
	let power = BigInt(base66String.length - 1);
  
	for (const char of base66String) {
	  const index = BigInt(BASE66_DIGITS.indexOf(char));
	  if (index === -1n || index >= base) {
		throw new Error(`Invalid base${b} character`);
	  }
	  result += index * (base ** power);
	  power--;
	}
  
	return result;
  }
function toBase66(num: bigint|number):string {
	if (typeof(num) === "number")
		num = BigInt(num);
	const base:bigint = 66n;
  
	if (num === 0n) {
	  return "0";
	}
  
	const result = [];
	while (num > 0n) {
	  const remainder = num % base;
	  result.unshift(BASE66_DIGITS[Number(remainder)]);
	  num = num / base;
	}
  
	return result.join("");
}

function fromBase66(base66String: string): bigint {
	if (base66String == null || typeof(base66String) === "undefined")
		return null;
	const base:bigint = 66n;
  
	let result = 0n;
	let power = BigInt(base66String.length - 1);
  
	for (const char of base66String) {
	  const index = BigInt(BASE66_DIGITS.indexOf(char));
	  if (index === -1n) {
		throw new Error("Invalid base66 character");
	  }
	  result += index * (base ** power);
	  power--;
	}
  
	return result;
  }

function toBase2(num: bigint|number): string {
	if (typeof(num) === "number")
		num = BigInt(num);
	if (num === 0n) {
	  return "0";
	}
  
	let binary = "";
	while (num > 0n) {
	  binary = (num % 2n) + binary;
	  num = num / 2n;
	}
  
	return binary;
  }
  
  function fromBase2(binaryString: string): bigint {
	let result = 0n;
	let power = BigInt(binaryString.length - 1);
  
	for (const char of binaryString) {
	  if (char !== "0" && char !== "1") {
		throw new Error("Invalid binary character");
	  }
	  result += BigInt(char) * 2n ** power;
	  power--;
	}
  
	return result;
  }

export enum STATE {
	UNSELECTED = "0",
	SELECTED = "1",
	BLUE = "2",
	BLACK = "3",
}

export namespace STATE {
	export function next(state:STATE) {
		switch (state) {
			case STATE.UNSELECTED:
				return STATE.SELECTED;
			case STATE.SELECTED:
				return STATE.UNSELECTED;
		}

		return STATE.UNSELECTED;
	}

	export function getName(state:STATE) {
		switch (state) {
			case STATE.UNSELECTED:
				return "UNSELECTED";
			case STATE.SELECTED:
				return "SELECTED";
			case STATE.BLUE:
				return "BLUE";
				// return "HIGHLIGHTED";
			case STATE.BLACK:
				return "BLACK";
		}
		
		return "SECRET";
	}
	
	export function getColor(state:STATE) {
		switch (state) {
			case STATE.UNSELECTED:
				return "bg-violet-transparent-70";
			case STATE.SELECTED:
				return "bg-violet-transparent-70";
			case STATE.BLUE:
				return "bg-blue-transparent-70";
				// return "HIGHLIGHTED";
			case STATE.BLACK:
				return "bg-black-transparent-70";
		}

	}

	export function getAll() {
		return [
			STATE.UNSELECTED,
			STATE.SELECTED,
			STATE.BLUE,
			STATE.BLACK,
		]
	}


}
const BASE = 5;

const DEFAULT_TITLE = "ROSTER";
const STATES = STATE.getAll();
const MYSTICS = [1, 2, 3]
const FORMATION = ["Striker", "Special"]
export default function Roster() {
	const [searchParams, setSearchParams] = useSearchParams();
	const [title, setTitle] = useState(searchParams.get("title") ?? DEFAULT_TITLE);
	const [toasts, setToasts] = useState([]);
	function pushToast(toast:string) {
		setToasts([...toasts, toast])
	}
	const rosterDiv = useRef(null);
	// console.log(toBase66(fromBaseN("2222222222", 3)))
	const BULLETS = [...new Set(StudentMap.schaleStudent.map(s=>s.BulletType))].sort();
	const ARMORS = [...new Set(StudentMap.schaleStudent.map(s=>s.ArmorType))].sort();
	const TACTICS = [...new Set(StudentMap.schaleStudent.map(s=>s.TacticRole))].sort();
	const fStates = searchParams.getAll("fState");
	const [filterStatus, setFilterStatus] = useState<STATE[]>(fStates.length > 0 ? fStates.map(f => f as STATE) : STATES);
	const [filterMystic, setFilterMystic] = useState(MYSTICS);
	const [filterAtk, setFilterAtk] = useState(BULLETS);
	const [filterDef, setFilterDef] = useState(ARMORS);
	const [filterTactic, setFilterTactic] = useState(TACTICS);
	const [filterFormation, setFilterFormation] = useState(FORMATION);
	const [s10, setS10] = useState(([...(toBaseN(fromBase66(searchParams.get("s10")), BASE) ?? "").split("").reverse(), ..."0".repeat(200)]));
	const [s13, setS13] = useState(([...(toBaseN(fromBase66(searchParams.get("s13")), BASE) ?? "").split("").reverse(), ..."0".repeat(200)]));
	const [s16, setS16] = useState(([...(toBaseN(fromBase66(searchParams.get("s16")), BASE) ?? "").split("").reverse(), ..."0".repeat(200)]));
	const [s20, setS20] = useState(([...(toBaseN(fromBase66(searchParams.get("s20")), BASE) ?? "").split("").reverse(), ..."0".repeat(200)]));
	const [s23, setS23] = useState(([...(toBaseN(fromBase66(searchParams.get("s23")), BASE) ?? "").split("").reverse(), ..."0".repeat(200)]));
	const [s26, setS26] = useState(([...(toBaseN(fromBase66(searchParams.get("s26")), BASE) ?? "").split("").reverse(), ..."0".repeat(200)]));
	const [s30, setS30] = useState(([...(toBaseN(fromBase66(searchParams.get("s30")), BASE) ?? "").split("").reverse(), ..."0".repeat(200)]));
	const [s33, setS33] = useState(([...(toBaseN(fromBase66(searchParams.get("s33")), BASE) ?? "").split("").reverse(), ..."0".repeat(200)]));
	const [s36, setS36] = useState(([...(toBaseN(fromBase66(searchParams.get("s36")), BASE) ?? "").split("").reverse(), ..."0".repeat(200)]));
	function getStatus(studentId:string) {
		return getCategoryState(studentId)[parseInt(studentId.substring(2))] as STATE;
	}
	const display = useMemo(() => {
		return StudentMap.schaleStudent.filter(student => {
			if (filterStatus.indexOf(getStatus(student.Id.toString())) < 0)
				return false;
			if (filterMystic.indexOf(student.StarGrade) < 0)
				return false;
			if (filterAtk.indexOf(student.BulletType) < 0)
				return false;
			if (filterDef.indexOf(student.ArmorType) < 0)
				return false;
			if (filterTactic.indexOf(student.TacticRole) < 0)
				return false;
			switch (student.Id.toString()[0]) {
				case "1": 
					if (filterFormation.indexOf("Striker") < 0)
						return false;
					break;
				case "2":
					if (filterFormation.indexOf("Special") < 0)
						return false;
					break;
			}
			
			return true;
		}).sort((a, b) => a.DefaultOrder - b.DefaultOrder);
	}, [
		filterStatus,
		filterMystic,
		filterAtk,
		filterDef,
		filterTactic,
		filterFormation,
		s10,
		s13,
		s16,
		s20,
		s23,
		s26,
		s30,
		s33,
		s36,
	]);
	useEffect(()=>{
		setSearchParams( params => {
			params.delete("fState")
			if (filterStatus.length < STATES.length) {
				for ( const fState of filterStatus) {
					params.append("fState", fState);
				}
			}
			return params;
		})
	},[filterStatus])
	useEffect(()=>{
		setSearchParams( params => {
			if(title?.length > 0 && title !== DEFAULT_TITLE) {
				params.set("title", title)
			} else {
				params.delete("title")
			}
			return params;
		})
	},[title])
	useEffect(()=>{
		setSearchParams( params => {
			if(s10) {
				const str = toBase66(fromBaseN([...s10].reverse().join(""), BASE));
				if (str !== "0") {
					params.set("s10", str)
				} else {
					params.delete("s10")
				}
			}
			return params;
		})
	},[s10])
	useEffect(()=>{
		setSearchParams( params => {
			if(s13) {
				const str = toBase66(fromBaseN([...s13].reverse().join(""), BASE));
				if (str !== "0") {
					params.set("s13", str)
				} else {
					params.delete("s13")
				}
			}
			return params;
		})
	},[s13])
	useEffect(()=>{
		setSearchParams( params => {
			if(s16) {
				const str = toBase66(fromBaseN([...s16].reverse().join(""), BASE));
				if (str !== "0") {
					params.set("s16", str)
				} else {
					params.delete("s16")
				}
			}
			return params;
		})
	},[s16])
	useEffect(()=>{
		setSearchParams( params => {
			if(s20) {
				const str = toBase66(fromBaseN([...s20].reverse().join(""), BASE));
				if (str !== "0") {
					params.set("s20", str)
				} else {
					params.delete("s20")
				}
			}
			return params;
		})
	},[s20])
	useEffect(()=>{
		setSearchParams( params => {
			if(s23) {
				const str = toBase66(fromBaseN([...s23].reverse().join(""), BASE));
				if (str !== "0") {
					params.set("s23", str)
				} else {
					params.delete("s23")
				}
			}
			return params;
		})
	},[s23])
	useEffect(()=>{
		setSearchParams( params => {
			if(s26) {
				const str = toBase66(fromBaseN([...s26].reverse().join(""), BASE));
				if (str !== "0") {
					params.set("s26", str)
				} else {
					params.delete("s26")
				}
			}
			return params;
		})
	},[s26])
	useEffect(()=>{
		setSearchParams( params => {
			if(s30) {
				const str = toBase66(fromBaseN([...s30].reverse().join(""), BASE));
				if (str !== "0") {
					params.set("s30", str)
				} else {
					params.delete("s30")
				}
			}
			return params;
		})
	},[s30])
	useEffect(()=>{
		setSearchParams( params => {
			if(s33) {
				const str = toBase66(fromBaseN([...s33].reverse().join(""), BASE));
				if (str !== "0") {
					params.set("s33", str)
				} else {
					params.delete("s33")
				}
			}
			return params;
		})
	},[s33])
	useEffect(()=>{
		setSearchParams( params => {
			if(s36) {
				const str = toBase66(fromBaseN([...s36].reverse().join(""), BASE));
				if (str !== "0") {
					params.set("s36", str)
				} else {
					params.delete("s36")
				}
			}
			return params;
		})
	},[s36])

	function getCategoryState(studentId:string) {
		switch (studentId.substring(0, 2)) {
			case "10": return s10;
			case "13": return s13;
			case "16": return s16;
			case "20": return s20;
			case "23": return s23;
			case "26": return s26;
			case "30": return s30;
			case "33": return s33;
			case "36": return s36;
		}

		return [];
	}
	function setCategoryState(studentId:string, val:string[]) {
		switch (studentId.substring(0, 2)) {
			case "10": return setS10(val);
			case "13": return setS13(val);
			case "16": return setS16(val);
			case "20": return setS20(val);
			case "23": return setS23(val);
			case "26": return setS26(val);
			case "30": return setS30(val);
			case "33": return setS33(val);
			case "36": return setS36(val);
		}
		return null;
	}


	function setStudentState(studentId:string, newState:STATE) {
		let state = getCategoryState(studentId);
		if (!state) return;
		const i = parseInt(studentId.substring(2));
		state[i] = newState.toString();
		setCategoryState(studentId, [...state]);
	}

	function toggleStudent(studentId:string) {
		let state = getCategoryState(studentId);
		if (!state) return;
		const i = parseInt(studentId.substring(2));
		setStudentState(studentId, STATE.next((state[i] as STATE)))
	}
	const [isPic, setIsPic]=useState(false);
	async function downloadImage() {
		setIsPic(true);
		try {
			const su = rosterDiv.current;
			const dataUrl = await domToImage.toPng(su);
			const link = document.createElement('a');
			link.href = dataUrl;
			link.download = title+'.png';
			link.click();
		} catch (error) {
			pushToast('Error capturing image:'+error);
			console.error('Error capturing or copying image:', error);
		}
		setIsPic(false);
	}
	async function copyImage() {
		setIsPic(true);
		try {
			const su = rosterDiv.current;
			const dataUrl = await domToImage.toPng(su);
			const canvas:any = document.createElement('canvas');
			// console.log(su.parentElement.style.background)
			// canvas.bgcolor = "red"
			// canvas.bgcolor = window.getComputedStyle(su).getPropertyValue('--bs-body-bg')
			canvas.width = su.offsetWidth;
			canvas.height = su.offsetHeight;
			const ctx = canvas.getContext('2d');
			const img = new Image();
			img.src = dataUrl;
			img.onload = () => {
				ctx?.drawImage(img, 0, 0);
				canvas.toBlob(function(blob) { 
					const item = new ClipboardItem({ "image/png": blob });
					navigator.clipboard.write([item]); 
					pushToast("Copied to clipboard");
				});
			};
		} catch (error) {
			pushToast('Error capturing image:'+error);
			console.error('Error capturing or copying image:', error);
		}
		setIsPic(false);
	}
	
	const [contextMenuPos, setContextMenuPos] = useState({
		x: 0,
		y: 0
	})
	const [contextStudent, setContextStudent] = useState<SchaleStudent>(null)

	function StudentContextMenu() {
		if (!!!contextStudent) return null;
		return <Col className="show dropdown-menu" style={{
			position: "absolute",
			top: contextMenuPos.y,
			 left: contextMenuPos.x
		}}>
			<NavDropdown.Item disabled={getStatus(contextStudent.Id.toString()) === STATE.SELECTED}>
				<Nav.Item onClick={()=>setStudentState(contextStudent.Id.toString(), STATE.SELECTED)} key="Select">Select</Nav.Item>
			</NavDropdown.Item>
			<NavDropdown.Item disabled={getStatus(contextStudent.Id.toString()) === STATE.UNSELECTED}>
				<Nav.Item onClick={()=>setStudentState(contextStudent.Id.toString(), STATE.UNSELECTED)} key="Deselect">Deselect</Nav.Item>
			</NavDropdown.Item>
			<NavDropdown.Item disabled={getStatus(contextStudent.Id.toString()) === STATE.BLUE}>
				<Nav.Item onClick={()=>setStudentState(contextStudent.Id.toString(), STATE.BLUE)} key="A">{STATE.getName(STATE.BLUE)}</Nav.Item>
			</NavDropdown.Item>
			<NavDropdown.Item disabled={getStatus(contextStudent.Id.toString()) === STATE.BLACK}>
				<Nav.Item onClick={()=>setStudentState(contextStudent.Id.toString(), STATE.BLACK)} key="B">{STATE.getName(STATE.BLACK)}</Nav.Item>
			</NavDropdown.Item>
			<NavDropdown.ItemText className="text-pink text-center" key="Name">{contextStudent.getDisplayName()}</NavDropdown.ItemText>
        </Col>
	}
	const card = document.querySelector(".student")?.getBoundingClientRect();
	const screenshotWidth = (card?.width ?? 0)*Math.min(16, Math.max(6, (Math.floor(Math.pow(display.length, 0.5)))));

	return (<div onClick={()=>setContextStudent(null)}>
	<Row >
		<Col>
			<IconButton 
				image="/logo512.png"
				label="📷 Screenshot"
				onClick={async () => copyImage()}
			/>
		</Col>
		<Col>
			<IconButton 
				image="/logo512.png"
				label="📷 Download"
				onClick={async () => downloadImage()}
			/>
		</Col>
		<Col>
			<IconButton 
				image="/logo512.png"
				label="Share URL"
				onClick={async () => {
					const shareText = window.location.href;
					copyToClipboard(shareText);
					pushToast("URL has put in your clipboard!")
				}}
			/>
		</Col>
	</Row>
	<Row  className="filters">
		<Col>
			<Accordion>
				<Accordion.Item eventKey="0">
					<AccordionHeader><h4>Settings</h4></AccordionHeader>
					<AccordionBody>
						<Row>
							<Col>
								<label>Title</label>
								<input id="title-input" type="text" maxLength={30} value={title} onChange={(e)=>setTitle(e.target.value)}></input>
							</Col>
						</Row>
						<Row><Col><h5 className="text-pink">Filters</h5></Col></Row>
						<Row style={styles.filterRow}>
							<Col xs={12}>
								<label>State [Persistent]</label>
							</Col>
						</Row>
						<Row>
							<Col><Form.Check // prettier-ignore
								checked={filterStatus.length > 0}
								onClick={()=>{
									if (filterStatus.length > 0) {
										setFilterStatus([])
									} else {
										setFilterStatus([...STATES])
									}
								}}
								key={`state_all`}
								type="radio"
								id={`state_all`}
								label="ALL"
							/></Col>
							{STATES.map(state => {
								return <Col><Form.Check // prettier-ignore
								checked={filterStatus.indexOf(state) >= 0}
								onClick={()=>{
									if (filterStatus.indexOf(state) >= 0) {
										setFilterStatus(filterStatus.filter(fa => fa !== state))
									} else {
										setFilterStatus([...filterStatus, state])
									}
								}}
								key={`states_${state}`}
								type="radio"
								id={`states_${state}`}
								label={STATE.getName(state)}
							/></Col>
							})}
						</Row>
						<hr></hr>
						<Row>
							<Col><Form.Check // prettier-ignore
								checked={filterMystic.length > 0}
								onClick={()=>{
									if (filterMystic.length > 0) {
										setFilterMystic([])
									} else {
										setFilterMystic([...MYSTICS])
									}
								}}
								key={`mystic_all`}
								type="radio"
								id={`mystic_all`}
								label="ALL"
							/></Col>
							{MYSTICS.map(mystic => {
								return <Col><Form.Check // prettier-ignore
								checked={filterMystic.indexOf(mystic) >= 0}
								onClick={()=>{
									if (filterMystic.indexOf(mystic) >= 0) {
										setFilterMystic(filterMystic.filter(fa => fa !== mystic))
									} else {
										setFilterMystic([...filterMystic, mystic])
									}
								}}
								key={`mystic_${mystic}`}
								type="radio"
								id={`mystic_${mystic}`}
								label={`${mystic}${STAR_HOLLOW}`}
							/></Col>
							})}
						</Row>
						<hr></hr>
						<Row>
							<Col><Form.Check // prettier-ignore
								checked={filterAtk.length > 0}
								onClick={()=>{
									if (filterAtk.length > 0) {
										setFilterAtk([])
									} else {
										setFilterAtk([...BULLETS])
									}
								}}
								key={`atk_all`}
								type="radio"
								id={`atk_all`}
								label="ALL"
							/></Col>
							{BULLETS.map(type => {
								return <Col><Form.Check // prettier-ignore
								checked={filterAtk.indexOf(type) >= 0}
								onClick={()=>{
									if (filterAtk.indexOf(type) >= 0) {
										setFilterAtk(filterAtk.filter(fa => fa !== type))
									} else {
										setFilterAtk([...filterAtk, type])
									}
								}}
								key={`atk_${type}`}
								type="radio"
								id={`atk_${type}`}
								label={`${type}`}
							/></Col>
							})}
						</Row>
						<hr></hr>
						<Row>
							<Col><Form.Check // prettier-ignore
								checked={filterDef.length > 0}
								onClick={()=>{
									if (filterDef.length > 0) {
										setFilterDef([])
									} else {
										setFilterDef([...ARMORS])
									}
								}}
								key={`def_all`}
								type="radio"
								id={`def_all`}
								label="ALL"
							/></Col>
							{ARMORS.map(type => {
								return <Col><Form.Check // prettier-ignore
								checked={filterDef.indexOf(type) >= 0}
								onClick={()=>{
									if (filterDef.indexOf(type) >= 0) {
										setFilterDef(filterDef.filter(fa => fa !== type))
									} else {
										setFilterDef([...filterDef, type])
									}
								}}
								key={`def_${type}`}
								type="radio"
								id={`def_${type}`}
								label={`${type}`}
							/></Col>
							})}
						</Row>
						<hr></hr>
						<Row>
							<Col><Form.Check // prettier-ignore
								checked={filterTactic.length > 0}
								onClick={()=>{
									if (filterTactic.length > 0) {
										setFilterTactic([])
									} else {
										setFilterTactic([...TACTICS])
									}
								}}
								key={`tactic_all`}
								type="radio"
								id={`tactic_all`}
								label="ALL"
							/></Col>
							{TACTICS.map(type => {
								return <Col><Form.Check // prettier-ignore
								checked={filterTactic.indexOf(type) >= 0}
								onClick={()=>{
									if (filterTactic.indexOf(type) >= 0) {
										setFilterTactic(filterTactic.filter(fa => fa !== type))
									} else {
										setFilterTactic([...filterTactic, type])
									}
								}}
								key={`role_${type}`}
								type="radio"
								id={`role_${type}`}
								label={`${type}`}
							/></Col>
							})}
						</Row>
						<hr></hr>
						<Row>
							<Col><Form.Check // prettier-ignore
								checked={filterFormation.length > 0}
								onClick={()=>{
									if (filterFormation.length > 0) {
										setFilterFormation([])
									} else {
										setFilterFormation([...FORMATION])
									}
								}}
								key={`formation_all`}
								type="radio"
								id={`formation_all`}
								label="ALL"
							/></Col>
							{FORMATION.map(type => {
								return <Col><Form.Check // prettier-ignore
								checked={filterFormation.indexOf(type) >= 0}
								onClick={()=>{
									if (filterFormation.indexOf(type) >= 0) {
										setFilterFormation(filterFormation.filter(fa => fa !== type))
									} else {
										setFilterFormation([...filterFormation, type])
									}
								}}
								key={`formation_${type}`}
								type="radio"
								id={`formation_${type}`}
								label={`${type}`}
							/></Col>
							})}
						</Row>
						<hr></hr>
						<Row><Col><h5 className="text-pink">Import Soon?</h5></Col></Row>
						<Row><Col><h5 className="text-pink">Sort Soon?</h5></Col></Row>
						<Row>
							<Col>
								<Button 
									variant="violet"
									onClick={() => {
										for(const student of display) {
											setStudentState(student.Id.toString(), STATE.SELECTED);
										}
									}}
								>Select All Visible</Button>
								<Button 
									variant="violet"
									onClick={() => {
										for(const student of display) {
											setStudentState(student.Id.toString(), STATE.UNSELECTED);
										}
									}}
								>Deselect All Visible</Button>
								<Button 
									variant="violet"
									onClick={() => {
										for(const student of display) {
											setStudentState(student.Id.toString(), STATE.BLUE);
										}
									}}
								>Set All Visible to {STATE.getName(STATE.BLUE)}</Button>
								<Button 
									variant="violet"
									onClick={() => {
										for(const student of display) {
											setStudentState(student.Id.toString(), STATE.BLACK);
										}
									}}
								>Set All Visible to {STATE.getName(STATE.BLACK)}</Button>
							</Col>
						</Row>
					</AccordionBody>
				</Accordion.Item>
			</Accordion>
		</Col>
	</Row>
	<Row><Col>
		<Alert dismissible>
			Tips: 
			<ul>
				<li>Click or Tap to toggle between {STATE.getName(STATE.UNSELECTED)} and {STATE.getName(STATE.SELECTED)}</li>
				<li>Hold <span className="text-pink">CTRL</span> while clicking to set to {STATE.getName(STATE.SELECTED)}</li>
				<li>Hold <span className="text-pink">SHIFT</span> while clicking to set to {STATE.getName(STATE.BLUE)}</li>
				<li>Hold <span className="text-pink">ALT</span> while clicking to set to {STATE.getName(STATE.BLACK)}</li>
				<li>Right Click or Long Press to open context menu and select which state to set</li>
			</ul>
		</Alert>
	</Col></Row>
	<Row id="roster" className="bg-body-secondary">
		<Col>
			<Row>
				<Col>
					<h1 className="text-pink">{title}</h1>
				</Col>
			</Row>
			<Row style={{...styles.container,display:"flex"}} className="noselect responsive-font full-list">
				{display.map(student => {
					const id = student.Id.toString();
					const studentState = getCategoryState(id)[parseInt(id.substring(2))] as STATE;
					return <Col className="student" style={{
						opacity: studentState == STATE.UNSELECTED ? 0.2 : 1,
					}} 
					onContextMenu={(e) => {
						e.preventDefault();
						setContextMenuPos({
							x: e.pageX,
							y: e.pageY,
						});
						setContextStudent(student)
					}}
					onClick={(e) => {
						if (e.ctrlKey) {
							setStudentState(id, STATE.SELECTED);
						} else if (e.shiftKey) {
							setStudentState(id, STATE.BLUE);
						} else if (e.altKey) {
							setStudentState(id, STATE.BLACK);
						} else {
							toggleStudent(id)
						}
						// flip(parseInt(id.substring(2)))
					}}><StudentRenderer nameplate={STATE.getColor(studentState)} bare model={Student.fromId(id)} /></Col>
				})}
			</Row>
			<Row>
				<Col>
					<MyBrand/>
				</Col>
			</Row>
		</Col>
	</Row>
	<Row id="screenshot-roster" hidden={!isPic} ref={rosterDiv} className="bg-body-secondary" style={{minWidth: `${screenshotWidth}px`, width: `${screenshotWidth}px`
	}}>
		<Col>
			<Row>
				<Col>
					<h1 className="text-pink">{title}</h1>
				</Col>
			</Row>
			<Row style={{...styles.container,display:"flex"}} className="responsive-font full-list">
				{display.map(student => {
					const id = student.Id.toString();
					const studentState = getCategoryState(id)[parseInt(id.substring(2))] as STATE;
					return <Col className="student" style={{
						opacity: studentState == STATE.UNSELECTED ? 0.2 : 1,
					}}>
						<StudentRenderer disableSchale nameplate={STATE.getColor(studentState)} bare model={Student.fromId(id)} />
					</Col>
				})}
			</Row>
			<Row style={{textAlign: "center", marginTop: "0.2em"}}><Col><CurrentQR size={256}></CurrentQR></Col></Row>
			<Row>
				<Col>
					<MyBrand/>
				</Col>
			</Row>
		</Col>
	</Row>
	<StudentContextMenu />
	<MyToastContainer>
		{toasts.map((toast) => <MyToast>
			<Toast.Header closeButton>
				<strong className="me-auto">Alert!</strong>
			</Toast.Header>
			<Toast.Body>{toast}</Toast.Body>
		</MyToast>)}
	</MyToastContainer>
	<MyLoadingSpinner overlay isLoading={isPic} />
	</div>);
}
