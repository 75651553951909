import StudentRenderer from "Components/ModelRenderers/StudentRenderer";
import IStyle from "Interfaces/IStyle";
import { ClearUnitTsvRow, JfdStageTsvRow } from "Models/ClearUnitTsv";
import TeamFormation from "Models/TeamFormation";
import React from "react";
import { Accordion, Col, Row } from "react-bootstrap";
const styles:IStyle = {
	student: {
		// padding: 2
	}
}
function ClearUnitRenderer(props: {model: TeamFormation, label?: string, sublabel?: string, compact?: boolean}) {
	const {model, label, sublabel, compact} = props;
	if (!!!model) return null;
	return (
		<Row className="" style={{margin:5, padding:5}}>
			<Col>
				<Row>
					{model.strikers.map(student => {
						return <Col className="px-xl-4 px-lg-2 px-md-1 px-sm-1 px-0" style={styles.student} xs={3} md={2}><StudentRenderer link="/StudentInsights/%id%" compact={compact} model={student} /></Col>
					})}
				</Row>
				<Row>
					{model.specials.map(student => {
						return <Col className="px-xl-4 px-lg-2 px-md-1 px-sm-1 px-0" style={styles.student} xs={3} md={2}><StudentRenderer link="/StudentInsights/%id%" compact={compact} model={student} /></Col>
					})}
				</Row>
			</Col>
		</Row>
	)
}

interface IProps {
	model:ClearUnitTsvRow, 
	compact?: boolean,
	header: string,
}

interface IJfdProps {
	model: JfdStageTsvRow, 
	compact?: boolean,
}
export default function ClearUnitRowRenderer(props: IProps) {
	const {model, compact} = props;
	return (
	<Row>
		<Col>
			<Accordion defaultActiveKey="0" className="block">
				<Accordion.Item eventKey="0">
					<Accordion.Header>{props.header}</Accordion.Header>
					<Accordion.Body>
						<Row>
							<Col>
								<ClearUnitRenderer compact={compact} model={model.team} />
							</Col>
						</Row>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		</Col>
	</Row>);
}

export function JfdUnitRowRenderer(props: IJfdProps) {
	return <ClearUnitRowRenderer {...props} header={`Stage ${props.model.stage}`} />
}