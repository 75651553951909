import GenericCardRenderer from "Components/ModelRenderers/GenericCardRenderer";
import StudentRenderer from "Components/ModelRenderers/StudentRenderer";
import { Sticky } from "Components/Sticky";
import { TagBadge } from "Components/TagBadge";
import { SCORE_CALCULATORS } from "Constants/Common";
import IStyle from "Interfaces/IStyle";
import RaidRankTsvRow from "Models/RaidRankTsvRow";
import TeamFormation from "Models/TeamFormation";
import { secondsToTime } from "Utils/Common";
import React from "react";
import { Accordion, Col, Image, Row } from "react-bootstrap";
const styles:IStyle = {
	student: {
		// padding: 2
	}
}
function RaidLeaderboardTeamRenderer(props: {model: TeamFormation, label?: string, sublabel?: string, compact?: boolean}) {
	const {model, label, sublabel, compact} = props;
	if (!!!model) return null;
	let striker=0;
	let special=0;
	return (
		<Row className="" style={{margin:5, padding:5}}>
			<Col lg={2} className="text-center">
				<Sticky offset={70}>
					<h3 className="text-pink">{label ?? "Unit"}</h3>
					<h5 className="text-pink">{sublabel}</h5>
				</Sticky>
			</Col>
			<Col>
				<Row>
					<Col className="px-xl-4 px-lg-2 px-md-1 px-sm-1 px-0" style={styles.student} xs={3} md={2}><StudentRenderer link="/StudentInsights/%id%" compact={compact} model={model.strikers[striker++]} /></Col>
					<Col className="px-xl-4 px-lg-2 px-md-1 px-sm-1 px-0" style={styles.student} xs={3} md={2}><StudentRenderer link="/StudentInsights/%id%" compact={compact} model={model.strikers[striker++]} /></Col>
					<Col className="px-xl-4 px-lg-2 px-md-1 px-sm-1 px-0" style={styles.student} xs={3} md={2}><StudentRenderer link="/StudentInsights/%id%" compact={compact} model={model.strikers[striker++]} /></Col>
					<Col className="px-xl-4 px-lg-2 px-md-1 px-sm-1 px-0" style={styles.student} xs={3} md={2}><StudentRenderer link="/StudentInsights/%id%" compact={compact} model={model.strikers[striker++]} /></Col>
					<Col className="px-xl-4 px-lg-2 px-md-1 px-sm-1 px-0" style={styles.student} xs={3} md={2}><StudentRenderer link="/StudentInsights/%id%" compact={compact} model={model.specials[special++]} /></Col>
					<Col className="px-xl-4 px-lg-2 px-md-1 px-sm-1 px-0" style={styles.student} xs={3} md={2}><StudentRenderer link="/StudentInsights/%id%" compact={compact} model={model.specials[special++]} /></Col>
				</Row>
			</Col>
		</Row>
	)
}

export default function RaidLeaderboardRowRenderer(props: {model:RaidRankTsvRow, compact?: boolean}) {
	const {model, compact} = props;
	let timeTaken = "???";
	try {
		timeTaken = secondsToTime(SCORE_CALCULATORS.getCalculators()[model.getDifficultyNumber()].calculateSecondsElapsed(model.score))
	} catch {

	}
	return (
	<Row>
		<Col>
			<Accordion defaultActiveKey="0" className="block">
				<Accordion.Item eventKey="0">
					<Accordion.Header>#{model.rank.toLocaleString()} - {model.username} - {model.score.toLocaleString()} [{ timeTaken }]</Accordion.Header>
					<Accordion.Body>
						<Row>
							<Col  xs={12} md={2} lg={2} className="text-center">
								<Sticky offset={70}>
									<Row>
										<Col className="d-flex justify-content-center">
											<GenericCardRenderer model={model}></GenericCardRenderer>
										</Col>
									</Row>
									<Row>
										<Col>
											<Image style={{maxWidth: 50}} src={model?.getTierIcon()}></Image>
											<TagBadge>{model?.getTier()}</TagBadge>
											<TagBadge>{model?.getDifficulty()}</TagBadge>
										</Col>
									</Row>
									<Row>
										<small>{model.score.toLocaleString()} [{ timeTaken }]</small>
									</Row>
								</Sticky>
							</Col>

							<Col>
								{model.teams && model.hasTeamData()
								? model.teams.map((team, i) => 
									<span key={"Unit "+(i+1)}><RaidLeaderboardTeamRenderer compact={compact} label={"Unit "+(i+1)} sublabel={i==model.teams.length-1 ? "Final" : ""} model={team} /></span>)
								: <span key="Final Unit Only"><RaidLeaderboardTeamRenderer compact={compact} label="Final Unit Only" sublabel="Possibly Incomplete" model={model.finalTeam} /></span>
								}
							</Col>
						</Row>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		</Col>
	</Row>);
}
