import GenericCardRenderer from "Components/ModelRenderers/GenericCardRenderer";
import StudentRenderer from "Components/ModelRenderers/StudentRenderer";
import { Sticky } from "Components/Sticky";
import { TagBadge } from "Components/TagBadge";
import { SCORE_CALCULATORS } from "Constants/Common";
import { IArmorType } from "Interfaces/IRaidRankFilter";
import IStyle from "Interfaces/IStyle";
import GrandRaidRankTsvRow from "Models/GrandRaidRankTsvRow";
import TeamFormation from "Models/TeamFormation";
import { secondsToTime } from "Utils/Common";
import React from "react";
import { Accordion, Col, Image, Row } from "react-bootstrap";
const styles:IStyle = {
	student: {
		// padding: 2
	}
}
function GrandRaidLeaderboardTeamRenderer(props: {model: TeamFormation, label?: string, sublabel?: string, compact?: boolean}) {
	const {model, label, sublabel, compact} = props;
	if (!!!model) return null;
	let striker=0;
	let special=0;
	return (
		<Row className="" style={{margin:5, padding:5}}>
			<Col lg={2} className="text-center">
				<Sticky offset={70}>
					<h3 className="text-pink">{label ?? "Unit"}</h3>
					<h5 className="text-pink">{sublabel}</h5>
				</Sticky>
			</Col>
			<Col>
				<Row>
					<Col className="px-xl-4 px-lg-2 px-md-1 px-sm-1 px-0" style={styles.student} xs={3} md={2}><StudentRenderer link="/StudentInsights/%id%" compact={compact} model={model.strikers[striker++]} /></Col>
					<Col className="px-xl-4 px-lg-2 px-md-1 px-sm-1 px-0" style={styles.student} xs={3} md={2}><StudentRenderer link="/StudentInsights/%id%" compact={compact} model={model.strikers[striker++]} /></Col>
					<Col className="px-xl-4 px-lg-2 px-md-1 px-sm-1 px-0" style={styles.student} xs={3} md={2}><StudentRenderer link="/StudentInsights/%id%" compact={compact} model={model.strikers[striker++]} /></Col>
					<Col className="px-xl-4 px-lg-2 px-md-1 px-sm-1 px-0" style={styles.student} xs={3} md={2}><StudentRenderer link="/StudentInsights/%id%" compact={compact} model={model.strikers[striker++]} /></Col>
					<Col className="px-xl-4 px-lg-2 px-md-1 px-sm-1 px-0" style={styles.student} xs={3} md={2}><StudentRenderer link="/StudentInsights/%id%" compact={compact} model={model.specials[special++]} /></Col>
					<Col className="px-xl-4 px-lg-2 px-md-1 px-sm-1 px-0" style={styles.student} xs={3} md={2}><StudentRenderer link="/StudentInsights/%id%" compact={compact} model={model.specials[special++]} /></Col>
				</Row>
			</Col>
		</Row>
	)
}

function GrandRaidLeaderboardTeamTypeRenderer(props: {model:GrandRaidRankTsvRow, compact?: boolean, type: IArmorType, armorType?: IArmorType}) {
	const {model, compact, type} = props;
	const timeTaken = secondsToTime(SCORE_CALCULATORS.getCalculators()[model.getDifficultyNumber(type)].calculateSecondsElapsed(model.scores[type]))
	const armorType = props.armorType ?? "ALL";
	if (armorType !== "ALL" && armorType !== type) return null;
	return (
	<Row>
		<Col>
			<Row>
				<Col>
					<h5 className="text-pink">{type} <sub>{model.scores[type].toLocaleString()} [{timeTaken}]</sub></h5>
					
				</Col>
			</Row>
			<Row>
				<Col>
					{!!model?.teams && model?.teams[type]?.map((team, i) => <span key={"Unit "+(i+1)}>
						<GrandRaidLeaderboardTeamRenderer compact={compact} label={"Unit "+(i+1)} model={team} 
							sublabel={i==model.teams.ALL.length-1 ? "Final" : ""} />
					</span>)}
				</Col>
			</Row>
		</Col>
	</Row>
	)
}

export default function GrandRaidLeaderboardRowRenderer(props: {model:GrandRaidRankTsvRow, compact?: boolean, armorType?: IArmorType}) {
	const {model, compact} = props;
	// const timeTaken = {
	// 	RED: secondsToTime(SCORE_CALCULATORS.getCalculators()[model.getDifficultyNumber("RED")].calculateSecondsElapsed(model.scores.RED)),
	// 	YELLOW: secondsToTime(SCORE_CALCULATORS.getCalculators()[model.getDifficultyNumber("YELLOW")].calculateSecondsElapsed(model.scores.YELLOW)),
	// 	BLUE: secondsToTime(SCORE_CALCULATORS.getCalculators()[model.getDifficultyNumber("BLUE")].calculateSecondsElapsed(model.scores.BLUE)),
	// }
	const armors = model?.getArmors();
	return (
	<Row>
		<Col>
			<Accordion defaultActiveKey="0" className="block">
				<Accordion.Item eventKey="0">
					<Accordion.Header>#{model.rank.toLocaleString()} - {model.username} - {model.score.toLocaleString()}</Accordion.Header>
					<Accordion.Body>
						<Row>
							<Col  xs={12} md={2} lg={2} className="text-center">
								<Sticky offset={70}>
									<Row>
										<Col className="d-flex justify-content-center">
											<GenericCardRenderer model={model}></GenericCardRenderer>
										</Col>
									</Row>
									<Row>
										<Col>
											<Image style={{maxWidth: 50}} src={model?.getTierIcon()}></Image>
											<TagBadge>{model?.getTier()}</TagBadge>
										</Col>
									</Row>
									<Row>
										<Col>
											<TagBadge>{model?.getDifficulty(armors[0])}</TagBadge>
											<TagBadge>{model?.getDifficulty(armors[1])}</TagBadge>
											<TagBadge>{model?.getDifficulty(armors[2])}</TagBadge>
										</Col>
									</Row>
									<Row>
										<small>{model.score.toLocaleString()}</small>
										{/* <small>RED: {timeTaken.RED}</small>
										<small>YELLOW: {timeTaken.YELLOW}</small>
										<small>BLUE: {timeTaken.BLUE}</small> */}
									</Row>
								</Sticky>
							</Col>
							<Col>
								<GrandRaidLeaderboardTeamTypeRenderer type={armors[0]} {...props} />
								<GrandRaidLeaderboardTeamTypeRenderer type={armors[1]} {...props} />
								<GrandRaidLeaderboardTeamTypeRenderer type={armors[2]} {...props} />
							</Col>
						</Row>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		</Col>
	</Row>);
}
